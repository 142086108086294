body {
  margin: 0;
  padding: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(0, 0, 0);
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 10px;
  width: 100%;
}
